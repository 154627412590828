<div class="payments">
	<div class="payments-welcome">
		<div class="payments-welcome__header">
			<img src="assets/images/pictogram.svg" alt="" />
			<div class="payments-welcome__header-headline">
				<h2>Plățile dumneavoastră</h2>
				<h4>Registratura Online a Direcției de Urbanism Sector 4</h4>
			</div>
		</div>
		<div class="payments-welcome__buttons">
			<button (click)="openPaymentsModal()">
				<img class="btn-img" src="assets/icons/wallet.svg" alt="Achitați plăți nedebitate" />
				ACHITAȚI PLĂȚI NEDEBITATE
			</button>
			<button>
				<img class="btn-img" src="assets/icons/add.svg" alt="Adăugați plată" />
				ADĂUGAȚI PLATĂ
			</button>
		</div>
	</div>

	<app-announcements [slug]="pageSlug"></app-announcements>

	<div class="col-sm-12">
		<mat-card class="cards-wrapper_alert d-flex flex-row">
			<img src="assets/icons/attention.svg" alt="" />
			<div>
				<h6 class="font ps-3">ANUNȚ IMPORTANT!</h6>
				<mat-card-content class="font">
					Vă informăm că, începând cu data de 24 decembrie 2024, se vor desfășura lucrări de mentenanță în
					aplicația informatică, necesare pentru finalizarea procesului de închidere a anului fiscal 2024 și
					pregătirea deschiderii anului fiscal 2025 în cele mai bune condiții. Vă mulțumim!
				</mat-card-content> 
			</div>
		</mat-card>
	</div>

	<div class="payments-toggle">
		<div class="payments-toggle-container">
			<div class="chip" (click)="togglePayments($event)"
				[ngClass]="{ 'selected': showPayments, 'non-selected': !showPayments }">
				<img src="assets/icons/icon-history.svg" alt="Icon history" />
				<span>Istoric plăți</span>
			</div>
			<div class="chip" (click)="toggleCards($event)"
				[ngClass]="{ 'selected': !showPayments, 'non-selected': showPayments }">
				<img src="assets/icons/icon-card.svg" alt="Icon card" />
				<span>Carduri salvate</span>
			</div>
		</div>
		<button *ngIf="!showPayments" class="payments-button payments-button-three" mat-flat-button>
			<div class="payments-button-container">
				<img src="assets/icons/icon-addcard2.svg" alt="Icon download" />
				<span>Adăugați Card</span>
			</div>
		</button>
	</div>

	<div class="payments-content">
		<ng-template [ngIf]="showPayments">
			<div class="payment-item" *ngFor="let payment of payments">
				<div class="header">
					<span class="title">{{ payment.name }}</span>
					<div class="date">
						<span>{{ payment.date | date : "d.MM.yy" : "ro" }}</span>
						<span class="separator">/</span>
						<span>{{ payment.date | date : "HH:mm" : "ro" }}</span>
					</div>
				</div>
				<div class="content">
					<div class="main-content">
						<div class="item">
							<span class="title">Data plății</span>
							<div class="value">
								<span>{{ payment.date | date : "d.MM.yy" : "ro" }}</span>
								<span class="special">/</span>
								<span>{{ payment.date | date : "HH:mm" : "ro" }}</span>
							</div>
						</div>
						<div class="item">
							<span class="title">Total achitat</span>
							<div class="value">
								<span>{{ payment.value | number : "1.2-2" }}</span>
								<span class="special">RON</span>
							</div>
						</div>
						<div class="item">
							<span class="title">Metodă de plată</span>
							<span class="value">{{ payment.method }}</span>
						</div>
					</div>
					<mat-divider class="divider"></mat-divider>
					<div class="bottom-content">
						<img src="assets/icons/icon-doc.svg" alt="Payment proof" />
						<span class="footer-text">Dovada plății</span>
						<button class="payments-button payments-button-one" mat-stroked-button>
							<div class="payments-button-container">
								<img src="assets/icons/icon-download.svg" alt="Icon download" />
								<span>Descărcați</span>
							</div>
						</button>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template [ngIf]="!showPayments">
			<div class="payment-item" *ngFor="let card of savedCards">
				<div class="header">
					<span class="title">{{ card.type }}</span>
					<div class="date">
						<span>{{ card.savedDate | date : "d.MM.yy" : "ro" }}</span>
						<span class="separator">/</span>
						<span>{{ card.savedDate | date : "HH.mm" : "ro" }}</span>
					</div>
				</div>
				<div class="content">
					<div class="bottom-content">
						<img src="assets/icons/icon-doc.svg" alt="Payment proof" />
						<div class="main-content card">
							<div class="item">
								<span class="title">Titular</span>
								<span class="value">{{ card.ownerName }}</span>
							</div>
							<div class="item">
								<span class="title">Dată expirare</span>
								<div class="value">
									<span>{{
										card.expirationDate | date : "d.MM.yy" : "ro"
										}}</span>
									<span class="special">/</span>
									<span>{{ card.expirationDate | date : "HH.mm" : "ro" }}</span>
								</div>
							</div>
							<div class="item">
								<span class="title">Ultimele cifre</span>
								<span class="value">{{ card.lastDigits }}</span>
							</div>
						</div>
						<button class="payments-button payments-button-two" mat-stroked-button>
							<div class="payments-button-container">
								<img src="assets/icons/icon-edit.svg" alt="Icon download" />
								<span>Ștergeți</span>
							</div>
						</button>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>