<app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>

<div class="faq-container">
    <div class="faq-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="faq-welcome-card-content d-flex justify-content-between align-items-center align-self-stretch">
            <span>Declarație privind accesibilitatea</span>
        </div>
    </div>

    <div class="faq-content d-flex flex-column align-items-start align-self-stretch">
        <mat-accordion>
            <ng-container *ngIf="contentDetails">
                <!-- <p>{{ contentDetails.title }}</p> -->
                <div [innerHTML]="contentDetails.content"></div>
                <!-- <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{contentDetails.title}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div [innerHTML]="contentDetails.content"></div>
                </mat-expansion-panel> -->
                <mat-divider class="divider"></mat-divider>
            </ng-container>
        </mat-accordion>
    </div>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>