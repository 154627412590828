import { Component, Inject } from '@angular/core';
import { AuthService } from '../../auth-module/services/auth.service'
import { finalize } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'app-accessibility',
    templateUrl: './accessibility.component.html',
    styleUrls: ['./accessibility.component.scss']
})
export class AccessibilityComponent {
    contentDetails: any;
    isLoading = false;
    slug: string = 'accesibilitate';
    portalLive: boolean = environment.portalLive;

    constructor(
        private authService: AuthService,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        this.getContent();
    }

    getContent() {
        this.isLoading = true;
        this.authService.getContent(this.slug)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: async (res: any) => {
                    let response = (typeof res.status_code !== 'undefined' ? res : res.error);
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            this.isLoading = false;
                            this.contentDetails = response.data.find((item: any) => item.title = 'Accesibilitate');
                            return;
                        }
                    } else {
                        this.isLoading = false;
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        await this.notificationService.warningSwal(environment.config.customNotifications.headers.error, errorMessage, environment.config.customNotifications.icons.error);
                    }
                },
                error: (res: any) => {
                    this.isLoading = false;
                    this.notificationService.handleHttpError(res.error);
                }
            })
    }
}
