<app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>


<div class="faq-container">
    <div class="faq-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="faq-welcome-card-content d-flex justify-content-between align-items-center align-self-stretch">
            <div class="col-sm-12 col-md-8">
                <span>Informații privind<br>
                    Taxa specială pentru <span class="white-text">dezvoltare urbană</span></span>
            </div>
            <div class="col-sm-12 col-md-4 d-flex flex-column justify-content-between align-items-center">
                <button class="mt-lg-0 mt-3 d-flex justify-content-center align-items-center" type="button" (click)="redirectToDMU()">SOLICITĂ
                    TAXĂ</button>
                <!-- <button class="mt-lg-0 mt-3 d-flex justify-content-center align-items-center" type="button">PLATĂ
                    ONLINE</button> -->
                <div class="panel-front">
                    <p>Solicitarea taxei este disponibilă pentru cetățenii ce au rol fiscal și parcare de reședință / publică cu abonament nominală sau de către cetățenii ce nu sunt din Sectorul 4 București.</p>
                </div>
                
            </div>
        </div>
    </div>

    <div class="faq-content d-flex flex-column align-items-start align-self-stretch">
        <div class="row container-section">
            <div class="col-md-8 col-sm-8 d-flex flex-row justify-content-start align-items-center">
                <p class="m-0 ps-3">INFORMAȚII PUBLICE - HCL 272/12.12.2024</p>
            </div>
            <div class="col-md-4 col-sm-12 regulatory-acts-container d-flex flex-row justify-content-end">
                <button type="button" (click)="redirectTo(hclUrl)">
                    <span>
                        <img src="assets/icons/view.svg" alt="" />
                    </span>
                    VIZUALIZAȚI
                </button>
            </div>
        </div>
    </div>

    <div class="faq-content d-flex flex-column align-items-start align-self-stretch">
        <div class="row container-section" id="tsdu-info">
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center pb-2 mb-2"
            style="border-bottom: 2px solid #ccc;">
                <p class="m-0 ps-3">SUMAR</p>
            </div>
            <div class="col-sm-12 d-flex flex-column justify-content-start align-items-start p-3">
                <div class="scutire-info-forms-dgitl w-100 d-flex flex-column justify-content-start align-items-start"
                    *ngFor="let sumar of sumarList; let i = index">
                    <div class="scutire-header w-100">
                        <div class="row">
                            <div class="col-md-8 col-sm-12">
                                <p class="m-0 clickable-element" (click)="onClick(sumar.id)"><u>{{i + 1}}. {{sumar.title}}</u></p>
                            </div>
                        </div>
                    </div>
                    <div class="scutire-documente no-bullets w-100 d-flex flex-column align-items-start justify-content-start mt-3"
                        *ngIf="sumar.items.length > 0">
                        <ul>
                            <li *ngFor="let item of sumar.items">
                                <a (click)="onClick(item.id)">
                                    {{item.title}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <mat-divider class="divider"></mat-divider>
                </div>
            </div>
        </div>
    </div>

    <div class="faq-content d-flex flex-column align-items-start align-self-stretch">
        <div class="row container-section" #tsduInfo id="tsduInfo">
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center">
                <p class="m-0 ps-3">1. APLICABILITATE</p>
            </div>
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center">
                <mat-accordion class="w-100">
                    <mat-divider class="divider"></mat-divider>
                    <ng-container *ngFor="let row of FAQList['tsdu-info']">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{row.name}}</mat-panel-title>
                            </mat-expansion-panel-header>
                            <div [innerHTML]="row.observations"></div>
                        </mat-expansion-panel>
                        <mat-divider class="divider"></mat-divider>
                    </ng-container>
                </mat-accordion>
            </div>
        </div>
    </div>

    <div class="faq-content d-flex flex-column align-items-start align-self-stretch">
        <div class="row container-section" #tsduScutire id="tsduScutire">
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center pb-2 mb-2"
                style="border-bottom: 2px solid #ccc;">
                <p class="m-0 ps-3">2. SCUTIRE TAXĂ</p>
                <hr />
            </div>
            <div class="col-sm-12 d-flex flex-column justify-content-start align-items-start p-3">
                <div class="scutire-info-forms-dgitl w-100 d-flex flex-column justify-content-start align-items-start"
                    *ngFor="let section of scutiriLabels; let i = index">
                    <div class="scutire-header w-100">
                        <div class="row">
                            <div class="col-md-8 col-sm-12">
                                <p><u>{{section.letter}}. {{section.title}}</u></p>
                            </div>
                            <div class="col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-end scutire-btns" *ngIf="section.url !== '##'">
                                <a *ngIf="section.formsAllowed.pf == true" class="clickable-element" (click)="redirectToDgitl(1, section.catId, section.rowId)">
                                    Solicitare persoană fizică
                                </a>
                                <a *ngIf="section.formsAllowed.pj == true" class="clickable-element" (click)="redirectToDgitl(2, section.catId, section.rowId)">
                                    Solicitare persoană juridică
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="scutire-info w-100 mb-2 pb-2 d-flex flex-column align-items-start justify-content-start"
                        *ngIf="section.info.length > 0">
                        <p>
                            <i>Informații</i>
                        </p>
                        <ul>
                            <li *ngFor="let info of section.info">{{info}}</li>
                        </ul>
                    </div>
                    <div class="scutire-documente w-100 d-flex flex-column align-items-start justify-content-start"
                        *ngIf="section.documents.length > 0">
                        <p>
                            <i>Documente necesare</i>
                        </p>
                        <ul>
                            <li *ngFor="let doc of section.documents">{{doc}}</li>
                        </ul>
                    </div>
                    <mat-divider class="divider"></mat-divider>
                </div>
            </div>
        </div>
    </div>

    <div class="faq-content d-flex flex-column align-items-start align-self-stretch">
        <div class="row container-section" #tsduAlteSolicitari id="tsduAlteSolicitari">
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center pb-2 mb-2"
                style="border-bottom: 2px solid #ccc;">
                <p class="m-0 ps-3">3. Alte Solicitări</p>
                <hr />
            </div>
            <div class="col-sm-12 d-flex flex-column justify-content-start align-items-start p-3">
                <div class="scutire-info-forms-dgitl w-100 d-flex flex-column justify-content-start align-items-start"
                    *ngFor="let section of alteSolicitariLabels; let i = index">
                    <div class="scutire-header w-100">
                        <div class="row">
                            <div class="col-md-8 col-sm-12">
                                <p><u>{{section.letter}}. {{section.title}}</u></p>
                            </div>
                            <div class="col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-end scutire-btns" *ngIf="section.url !== '##'">
                                <a *ngIf="section.formsAllowed.pf == true" class="clickable-element" (click)="redirectToDgitl(1, section.catId, section.rowId)">
                                    Solicitare persoană fizică
                                </a>
                                <a *ngIf="section.formsAllowed.pj == true" class="clickable-element" (click)="redirectToDgitl(2, section.catId, section.rowId)">
                                    Solicitare persoană juridică
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="scutire-info w-100 mb-2 pb-2 d-flex flex-column align-items-start justify-content-start"
                        *ngIf="section.info.length > 0">
                        <p>
                            <i>Informații</i>
                        </p>
                        <ul>
                            <li *ngFor="let info of section.info">{{info}}</li>
                        </ul>
                    </div>
                    <div class="scutire-documente w-100 d-flex flex-column align-items-start justify-content-start"
                        *ngIf="section.documents.length > 0">
                        <p>
                            <i>Documente necesare</i>
                        </p>
                        <ul>
                            <li *ngFor="let doc of section.documents">{{doc}}</li>
                        </ul>
                    </div>
                    <mat-divider class="divider"></mat-divider>
                </div>
            </div>
        </div>
    </div>

    <div class="faq-content d-flex flex-column align-items-start align-self-stretch">
        <div class="row container-section" #tsduPayment id="tsduPayment">
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center">
                <p class="m-0 ps-3">4. MODALITĂȚI PLATĂ</p>
            </div>
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center">
                <mat-accordion class="w-100">
                    <mat-divider class="divider"></mat-divider>
                    <ng-container *ngFor="let row of FAQList['tsdu-payment']">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{row.name}}</mat-panel-title>
                            </mat-expansion-panel-header>
                            <div [innerHTML]="row.observations"></div>
                        </mat-expansion-panel>
                        <mat-divider class="divider"></mat-divider>
                    </ng-container>
                </mat-accordion>
            </div>
        </div>
    </div>

    <div class="faq-content d-flex flex-column align-items-start align-self-stretch">
        <div class="row container-section" #tsduFaq id="tsduFaq">
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center">
                <p class="m-0 ps-3">5. ÎNTREBĂRI FRECVENTE</p>
            </div>
        </div>
    </div>

    <div class="faq-content d-flex flex-column align-items-start align-self-stretch">
        <div class="row container-section" #tsduCetRolFiscal id="tsduCetRolFiscal">
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center">
                <p class="m-0 ps-3 text-uppercase">Cetățenii cu rol fiscal</p>
            </div>
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center">
                <mat-accordion class="w-100">
                    <mat-divider class="divider"></mat-divider>
                    <ng-container *ngFor="let row of FAQList['tsdu-cet-rol-fiscal']">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{row.name}}</mat-panel-title>
                            </mat-expansion-panel-header>
                            <div [innerHTML]="row.observations"></div>
                        </mat-expansion-panel>
                        <mat-divider class="divider"></mat-divider>
                    </ng-container>
                </mat-accordion>
            </div>
        </div>
    </div>

    <div class="faq-content d-flex flex-column align-items-start align-self-stretch">
        <div class="row container-section" #tsduCetRolFiscalLocNominal id="tsduCetRolFiscalLocNominal">
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center">
                <p class="m-0 ps-3 text-uppercase">Cetățean cu rol fiscal, loc de parcare nominal</p>
            </div>
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center">
                <mat-accordion class="w-100">
                    <mat-divider class="divider"></mat-divider>
                    <ng-container *ngFor="let row of FAQList['tsdu-cet-rol-fiscal-loc-nominal']">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{row.name}}</mat-panel-title>
                            </mat-expansion-panel-header>
                            <div [innerHTML]="row.observations"></div>
                        </mat-expansion-panel>
                        <mat-divider class="divider"></mat-divider>
                    </ng-container>
                </mat-accordion>
            </div>
        </div>
    </div>

    <div class="faq-content d-flex flex-column align-items-start align-self-stretch">
        <div class="row container-section" #tsduCetFaraRolFiscal id="tsduCetFaraRolFiscal">
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center">
                <p class="m-0 ps-3 text-uppercase">Cetățenii fără rol fiscal</p>
            </div>
            <div class="col-sm-12 d-flex flex-row justify-content-start align-items-center">
                <mat-accordion class="w-100">
                    <mat-divider class="divider"></mat-divider>
                    <ng-container *ngFor="let row of FAQList['tsdu-cet-fara-rol-fiscal']">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{row.name}}</mat-panel-title>
                            </mat-expansion-panel-header>
                            <div [innerHTML]="row.observations"></div>
                        </mat-expansion-panel>
                        <mat-divider class="divider"></mat-divider>
                    </ng-container>
                </mat-accordion>
            </div>
        </div>
    </div>

    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>