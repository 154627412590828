<div *ngIf="!isProduction()" class="topbar d-flex justify-content-between align-items-center align-self-stretch">
    <div class="topbar-first d-flex align-items-center align-self-stretch">
        <img routerLink="/" src="assets/images/LogoPS4+MB.png" [alt]="appName" />
        <a routerLink="/">{{ appName }}</a>
    </div>
    <div [ngClass]="{'d-none': sidenavOpen}" class="text-center topbar-icon" #menuButton>
        <mat-icon (click)="toggleSidenav()">menu</mat-icon>
    </div>
    <div [ngClass]="{'d-none': !sidenavOpen}" class="closeIcon topbar-icon" #closeButton>
        <mat-icon (click)="toggleSidenav()">close</mat-icon>
    </div>
    <div class="topbar-second">
        <a [class.active]="activeRoute === '/'" routerLink="">Acasă</a>
        <!-- <span [class.active]="activeRoute === '/noutati'" routerLink="/noutati">Noutăți</span> -->
        <a [class.active]="activeRoute === '/faq'" routerLink="/faq">Întrebări frecvente</a>
        <!-- <span [class.active]="activeRoute === '/utile'" routerLink="/utile">Utile</span> -->
        <!-- <a [class.active]="activeRoute === '/registre'" routerLink="/registre">Registre</a> -->
        <a [class.active]="activeRoute === '/contact'" routerLink="/contact">Contact</a>
        <a [class.active]="activeRoute === '/accesibilitate'" routerLink="/accesibilitate">Accesibilitate</a>
        <a (click)="openSuport()">Suport tehnic</a>
    </div>
    <div *ngIf="!authService.isAuthenticated()" class="topbar-third">
        <a routerLink="/pages/login">Autentificare</a>
    </div>
    <div *ngIf="authService.isAuthenticated()" class="username__container topbar-third">
        <div class="username__icon">
            <div class="username__icon--letters">{{ usernameLetters }}</div>
        </div>
        <div routerLink="/pages/login" class="username">{{ username }}</div>
    </div>
</div>
<div *ngIf="!isProduction()" class="sidenav-container" #sidenavContainer>
    <div class="sidenav" [class.open]="sidenavOpen">
        <div class="sidenav open-first">
            <span [class.active]="activeRoute === '/'" routerLink="" (click)="toggleSidenav()">Acasă</span>
            <!-- <span [class.active]="activeRoute === '/noutati'" routerLink="/noutati">Noutăți</span> -->
            <span [class.active]="activeRoute === '/faq'" routerLink="/faq" (click)="toggleSidenav()">Întrebări
                frecvente</span>
            <!-- <span [class.active]="activeRoute === '/utile'" routerLink="/utile">Utile</span> -->
            <!-- <span [class.active]="activeRoute === '/registre'" routerLink="/registre" (click)="toggleSidenav()">Registre</span> -->
            <span [class.active]="activeRoute === '/contact'" routerLink="/contact"
                (click)="toggleSidenav()">Contact</span>
            <span [class.active]="activeRoute === '/accesibilitate'" routerLink="/accesibilitate"
                (click)="toggleSidenav()">Accesibilitate</span>
            <span (click)="openSuportSidenav()">Suport tehnic</span>
        </div>
        <div *ngIf="!authService.isAuthenticated()" class="open-second">
            <span routerLink="/pages/login" (click)="toggleSidenav()">Autentificare</span>
        </div>
        <div *ngIf="authService.isAuthenticated()" class="username__container open-second">
            <div class="username__icon">
                <div class="username__icon--letters">{{ usernameLetters }}</div>
            </div>
            <div routerLink="/pages/login" class="username">{{ username }}</div>
        </div>
    </div>
</div>
<div class="pages-content">

    <app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>

    <router-outlet></router-outlet>
</div>