<div class="container-fluid m-0 ps-5 pe-5">
    <div class="welcome-card">
        <div class="welcome-card_header">
            <img src="assets/images/Group 9.svg" alt="" />
            <div class="welcome-card_header-headline">
                <h2>
                    Vizualizare {{ solicitare.programare_info ? 'Programare' : 'Solicitarea' }} - {{ solicitare.registration_number ?? solicitare.reg_numar }} / {{ solicitare.created }}
                </h2>
                <h4 *ngIf="solicitareType == 'DGITL'">Registratura Online a Direcției Generale de Impozite și Taxe Locale</h4>
                <h4 *ngIf="solicitareType == 'Suport'">Suport tehnic</h4>
                <h4 *ngIf="solicitareType == 'AutorizariComerciale'">Registratura Online a Serviciului Autorizări Comerciale</h4>
            </div>
        </div>
        <button class="initialize-request" (click)="navigateToAddRequest()">
            <img class="btn-img" src="assets/icons/add.svg" alt="Solicitarile dumneavoastră" /> ADAUGĂ SOLICITARE
        </button>
    </div>
    <div class="container-fluid p-4 m-0 pt-0">
        <div class="row p-3 g-4">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12 white-bg p-5" *ngIf="loaded == 1">
                        <div class="row">
                            <div class="col-sm-12 mb-5 d-flex align-items-center">
                                <button class="btn-transition btn btn-sm btn btn-outline-primary float-left" type="button" routerLink="/dashboard/solicitari">
                                    <mat-icon>keyboard_arrow_left</mat-icon>
                                    ÎNAPOI
                                </button>
                                <button *ngIf="solicitare.hasDraft" type="button" class="btn btn-primary nav-btn ms-3" (click)="generareDraft()" [disabled]="loading">
                                    <span>Vizualizare Cerere</span>
                                </button>
                            </div>

                            <div class="col-sm-12 mb-5">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <h4 class="border-bottom">Informații Solicitare</h4>
                                        <ul class="list-group p-0">
                                            <li class="list-group-item d-flex justify-content-between">
                                                <span>
                                                    <strong>Tip Solicitare</strong>
                                                </span>
                                                <span>{{ solicitare.subject }}</span>
                                            </li>
                                            <!-- <li class="list-group-item d-flex justify-content-between">
										<span><strong>Cetatean</strong></span>
										<span>{{ solicitare.full_name }}</span>
									</li> -->
                                            <li class="list-group-item d-flex justify-content-between">
                                                <span>
                                                    <strong>Status</strong>
                                                </span>
                                                <span class="badge" [ngStyle]="{
                            'background-color': solicitare.rank_color,
                            color: 'white'
                          }">{{ solicitare.rank_name }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                <span>
                                                    <strong>Dată creare</strong>
                                                </span>
                                                <span>{{ solicitare.created }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                <span>
                                                    <strong>Ultima actualizare</strong>
                                                </span>
                                                <span>{{ solicitare.updated }}</span>
                                            </li>

                                            <li *ngIf="solicitare.documente?.length > 0" class="list-group-item d-flex justify-content-between">
                                                <strong>Documente atașate solicitării</strong>
                                            </li>
                                            <li *ngFor="
                          let document of solicitare.documente;
                          let i = index
                        " class="doc-list list-group-item d-flex justify-content-between blue-color blue-text pointer">
                                                <span class="doc-label-name">{{ document.label }}</span>
                                                <div class="d-flex flex-wrap align-content-center">
                                                    <span class="doc-name">
                                                        <u>{{ document.name }}</u>
                                                    </span>
                                                    <button type="button" mat-icon-button (click)="openViewDialog($event, document.id, document.extension)" data-toggle="tooltip"
                                                        data-placement="top" title="Vizualizeaza documentul">
                                                        <mat-icon class="doc-action">visibility</mat-icon>
                                                    </button>
                                                    <button type="button" mat-icon-button (click)=" downloadToClient($event, document.id, document.name)" data-toggle="tooltip"
                                                        data-placement="top" title="Descarca documentul">
                                                        <mat-icon class="doc-action">cloud_download</mat-icon>
                                                    </button>
                                                </div>
                                            </li>
                                        </ul>







                                    <ng-container *ngIf="solicitare.programare_info">
                                        <h4 class="border-bottom mt-3">Informații Programare</h4>
                                        <ul class="list-group p-0">
                                            <li class="list-group-item d-flex justify-content-between">
                                                <span>
                                                    <strong>Denumire modul</strong>
                                                </span>
                                                <span>{{ solicitare.programare_info.modul_denumire }}</span>
                                            </li>                                        
                                            <li class="list-group-item d-flex justify-content-between">
                                                <span>
                                                    <strong>Tip programare</strong>
                                                </span>
                                                <span>{{ solicitare.programare_info.program_type_denumire }}</span>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between">
                                                <span>
                                                    <strong>Locație</strong>
                                                </span>
                                                <span>{{ solicitare.programare_info.locatie_denumire }}</span>
                                            </li>

                                            <li class="list-group-item d-flex justify-content-between">
                                                <span>
                                                    <strong>Data</strong>
                                                </span>
                                                <span>{{ solicitare.programare_info.date }}</span>
                                            </li>

                                            <li class="list-group-item d-flex justify-content-between">
                                                <span>
                                                    <strong>Oră început</strong>
                                                </span>
                                                <span>{{ solicitare.programare_info.time_from }}</span>
                                            </li>

                                            <li class="list-group-item d-flex justify-content-between">
                                                <span>
                                                    <strong>Oră sfârșit</strong>
                                                </span>
                                                <span>{{ solicitare.programare_info.time_end }}</span>
                                            </li>

                                            <li class="list-group-item d-flex justify-content-between">
                                                <span>
                                                    <strong>Status programare</strong>
                                                </span>
                                                <span class="badge" [ngStyle]="{'background-color': solicitare.programare_info.active === 1 ? 'green' : 'red', color: 'white'}">{{ solicitare.programare_info.active ? 'Activa' : 'Anulata' }}</span>
                                            </li>

                                            <li class="list-group-item d-flex justify-content-center" *ngIf="solicitare.programare_info.allow_anulare_programare && solicitare.programare_info.active">
                                                <button mat-raised-button color="warn" style="height: 64px;" (click)="cancelAppointment(solicitare.programare_info.id)">
                                                    <mat-icon style="margin-right: 5px; margin-left: -10px">delete</mat-icon>
                                                    <span>ANULEAZĂ PROGRAMAREA</span>
                                                </button>
                                            </li>

                                        </ul>
                                    </ng-container>




                                    </div>

                                    <div class="col-sm-12 col-md-6">
                                        <!-- <div class="row">
									<div class="col w-75 margin-0-auto white-bg p-3 white-bg">

										<p class="text-center red-text"><strong>ANUNȚ IMPORTANT!!!</strong></p>
										<br />
										<p>Se aplică mentenanța de sistem cât si actualizări de date, astfel incat serviciile de registratura pot fi nefuncționale.</p>
									</div>
								</div> -->
                                        <div class="row">
                                            <div class="col-sm-12" *ngIf="solicitare.chat_open == 1 && !solicitare.programare_info">
                                                <h4 class="border-bottom">Trimite Mesaj</h4>
                                                <form [formGroup]="form" (ngSubmit)="sendMessage()" class="mb-5">
                                                    <!-- nume -->
                                                    <mat-form-field class="example-full-width">
                                                        <mat-label>Observații</mat-label>
                                                        <textarea formControlName="content" matInput [errorStateMatcher]="matcher" type="text" required="required" rows="5"></textarea>

                                                        <mat-error class="p-2" *ngIf="
                                content &&
                                (content.dirty || content.touched) &&
                                content.hasError('required')
                              ">
                                                            <strong>Câmp obligatoriu</strong>
                                                        </mat-error>
                                                    </mat-form-field>

                                                    <div class="upload-container">
                                                        <div class="uploaded-files">
                                                            <p>Fotografii/Documente</p>
                                                            <ng-container>
                                                                <div class="uploaded-files-container">
                                                                    <div *ngFor="
                                      let row of files.documents;
                                      index as i
                                    " class="success-upload docs-list-uploaded-container">
                                                                        <p>{{ i + 1 }}. {{ row.original_name }}</p>
                                                                        <button type="button" (click)="removeFile('documents', i)">
                                                                            <img class="delete-icon" src="assets/icons/delete.svg" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <mat-spinner diameter="32" *ngIf="uploading.documents"></mat-spinner>
                                                        </div>
                                                        <div>
                                                            <label for="fotodoc">
                                                                <button type="button" *ngIf="!uploading.documents" (click)="fileDocumentInput.click()">
                                                                    <span>
                                                                        <img src="assets/icons/upload.svg" alt="" />
                                                                    </span>
                                                                    <span class="show_hide_text">Încărcați</span>
                                                                </button>
                                                                <input #fileDocumentInput type="file" id="fotodoc" (change)="upload($event, 'documents')" class="upload-input" multiple />
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class="row m-0 mt-3 d-flex justify-content-around">
                                                        <mat-hint class="my-3" style="font-size: 16px">
                                                            <img src="assets/icons/alert.svg" alt="" /> Câmpurile marcate cu <span class="red-text">(*)</span> sunt obligatorii
                                                        </mat-hint>
                                                        <button type="submit" [disabled]="!form.valid || isLoading" class="col-md-5 mr-2 btn btn-primary nav-btn">
                                                            TRIMITE
                                                        </button>
                                                        <div class="section-spinner position-absolute" *ngIf="isLoading">
                                                            <div class="spinner position-absolute top-50 start-50 translate-middle">
                                                                <mat-spinner></mat-spinner>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div class="divider"></div>
                                            </div>
                                            <div class="col-sm-12">
                                                <h4 class="border-bottom mb-5">Istoric Mesaje</h4>

                                                <div class="chat-wrapper">
                                                    <div class="chat-object mb-3" [ngClass]="{
                              cetatean: row.type == 0,
                              operator: row.type == 1
                            }" *ngFor="let row of istoric">
                                                        <div class="col-sm-8" [ngClass]="{
                                'float-left text-left': row.type == 1,
                                'float-right text-right': row.type == 0
                              }">
                                                            <div [innerHTML]="row.content"></div>
                                                            <ul class="clean-list">
                                                                <li *ngIf="row.documente.length > 0">
                                                                    <strong>Fotografii/Documente</strong>
                                                                </li>
                                                                <li *ngFor="
                                    let document of row.documente;
                                    let i = index
                                  " class="blue-color blue-text pointer">
                                                                    <span>{{ i + 1 }}.
                                                                        <u>{{ document.name }}</u>
                                                                        <button data-toggle="tooltip" data-placement="top" title="Vizualizează documentul" type="button" mat-icon-button (click)="
                                        openViewDialog(
                                          $event,
                                          document.id,
                                          document.extension
                                        )
                                      ">
                                                                            <mat-icon class="doc-action">visibility</mat-icon>
                                                                        </button>
                                                                        <button type="button" mat-icon-button (click)=" downloadToClient($event, document.id, document.name)" data-toggle="tooltip"
                                                                            data-placement="top" title="Descarcă documentul">
                                                                            <mat-icon class="doc-action">cloud_download</mat-icon>
                                                                        </button>
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                            <small [ngClass]="{
                                  'text-left': row.type == 0,
                                  'text-right': row.type == 1
                                }">{{ row.created }}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="text-center" *ngIf="istoric.length == 0">
                                                    Nu aveți niciun mesaj.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>